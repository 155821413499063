<template>
  <div id="community" class="center">
    community
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

})
</script>

<style lang="scss" scoped>

</style>